import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/SiteLayout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "variables"
    }}>{`Variables`}</h1>
    <p>{`In the previous chapter we looked at `}<em parentName="p">{`values`}</em>{` and `}<em parentName="p">{`expressions`}</em>{`. Recall that a `}<em parentName="p">{`value`}</em>{` is just some "thing" we work with when programming (such as a number or a word), and expressions are combinations of values that can be evaluated to a single value. In this chapter, we're going to look at a few problems we often run into when working with values and expressions, and a tool for solving those problems!`}</p>
    <h2 {...{
      "id": "problem-1-reusing-a-value"
    }}>{`Problem #1: Reusing a value`}</h2>
    <p>{`Suppose we wan to make a square. We don't know much about the square we're going to make, other than the fact that - since it's a square - it `}<em parentName="p">{`must`}</em>{` be a rectangle that has the same width and height. Let's go ahead and make one of those using our handy `}<inlineCode parentName="p">{`$rect`}</inlineCode>{` command.`}</p>
    <blockquote>
      <p parentName="blockquote">{`The `}<inlineCode parentName="p">{`$rect`}</inlineCode>{` command takes at least 2 values: the first is the width of the rectangle, and the second is the height of the rectangle. So `}<inlineCode parentName="p">{`$rect(100, 40)`}</inlineCode>{` would create a rectangle with a width of 100 px and a height of 40 px.`}</p>
    </blockquote>
    <p>{`Let's start our activity by creating a square that has a side length of `}<inlineCode parentName="p">{`70`}</inlineCode>{` px (pixels).`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "live=true id=variables-square1",
        "live": "true",
        "id": "variables-square1"
      }}>{`// Create a square.
$rect(70, 70);
`}</code></pre>
    <p>{`Now, by changing the line of code in the live code snippet above, do the following 3 things:`}</p>
    <ol>
      <li parentName="ol">{`Make the square have a side length of 100 px.`}</li>
      <li parentName="ol">{`Now make the square have a side length of 185 px.`}</li>
      <li parentName="ol">{`Now make the square have a side length of 15 px.`}</li>
    </ol>
    <p>{`You might have noticed something! Each time you wanted to change the size of the square, you had to change `}<em parentName="p">{`both the width and the height`}</em>{`. Wouldn't it be nice if we could streamline this a bit? Meet my friend, `}<strong parentName="p">{`variables`}</strong>{`. `}</p>
    <blockquote>
      <p parentName="blockquote">{`In programming, a `}<em parentName="p">{`variable`}</em>{` is a "name" that "points" to a value. In JavaScript we can use the keywords `}<inlineCode parentName="p">{`const`}</inlineCode>{` and `}<inlineCode parentName="p">{`let`}</inlineCode>{` to create variables. For example, `}<inlineCode parentName="p">{`const x = 3;`}</inlineCode>{` will create a variable named `}<inlineCode parentName="p">{`x`}</inlineCode>{` whose value is `}<inlineCode parentName="p">{`3`}</inlineCode>{`.`}</p>
    </blockquote>
    <p>{`This definition might seem a bit weird. Let's go back to our square example and see how we could use a variable to make our lives a bit easier.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "live=true id=variables-square2",
        "live": "true",
        "id": "variables-square2"
      }}>{`// This is a variable named \`sideLength\`.
// It has a value of 70.
const sideLength = 70;

// Create the square.
$rect(sideLength, sideLength);
`}</code></pre>
    <p>{`So... this code snippet has `}<em parentName="p">{`more code`}</em>{` than our last one 👀. So why would we do that? Let's go ahead and redo the tasks we looked at earlier: `}</p>
    <ol>
      <li parentName="ol">{`Make the square have a side length of 100 px.`}</li>
      <li parentName="ol">{`Now make the square have a side length of 185 px.`}</li>
      <li parentName="ol">{`Now make the square have a side length of 15 px.`}</li>
    </ol>
    <p>{`You should have noticed that now, instead of changing the side length in two locations, you can just change the value of the variable `}<inlineCode parentName="p">{`sideLength`}</inlineCode>{`!`}</p>
    <p>{`This is one very important use of variables. In this example, our variable was only used in two locations. But it's not uncommon in programming to use a variable in 10s or even 100s of different locations, and being able to change the value once saves a lot of time!`}</p>
    <h2 {...{
      "id": "problem-2-breaking-things-down"
    }}>{`Problem #2: Breaking things down`}</h2>
    <p>{`In the previous example, we used variables when we wanted to use the same value multiple times. However, sometimes we define variables and only used them once. This is especially useful when we have an `}<em parentName="p">{`expression`}</em>{` that's somewhat complicated and we want to use it's value later on without writing out the whole expression. Let's look at an example that will use two new features.`}</p>
    <blockquote>
      <p parentName="blockquote">{`In this book, we have added in two helper variables for you to use: `}<inlineCode parentName="p">{`$stageWidth`}</inlineCode>{` and `}<inlineCode parentName="p">{`$stageHeight`}</inlineCode>{` which will give you the width and height of the stage. If you resize the stage, these values will update accordingly.`}</p>
    </blockquote>
    <p>{`In this example, we're going to create a rectangle that has the following properties:`}</p>
    <ul>
      <li parentName="ul">{`The rectangle is half as wide as the stage.`}</li>
      <li parentName="ul">{`The rectangle is half as tall as the stage.`}</li>
    </ul>
    <p>{`Let's dig in!`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "live=true id=variables-rectangle",
        "live": "true",
        "id": "variables-rectangle"
      }}>{`// Define variables for the width and height
const width = $stageWidth / 2;
const height = $stageHeight / 2;

// Draw the rectangle
$rect(width, height);
`}</code></pre>
    <p>{`Go ahead and resize the stage and notice how the rectangle is always half as wide and half as tall as the stage. In this example, we defined variables `}<inlineCode parentName="p">{`width`}</inlineCode>{` and `}<inlineCode parentName="p">{`height`}</inlineCode>{` even though we only used them once. However, defining them makes the last line `}<inlineCode parentName="p">{`$rect(width, height)`}</inlineCode>{` much easier to read! Otherwise, we'd have to write `}<inlineCode parentName="p">{`$rect($stageWidth / 2, $stageHeight /2);`}</inlineCode>{`.`}</p>
    <p>{`As our expressions get more and more complicated, this pattern will help us keep our code readable and understandable!`}</p>
    <h2 {...{
      "id": "variables-with-changing-values"
    }}>{`Variables with changing values`}</h2>
    <p>{`In our examples so far, we have been using the keyword `}<inlineCode parentName="p">{`const`}</inlineCode>{` to define our variables. This is a JavaScript thing. But did you happen to think to yourself, "what the heck does `}<inlineCode parentName="p">{`const`}</inlineCode>{` even stand for?"? The keyword `}<inlineCode parentName="p">{`const`}</inlineCode>{` actually stands for "constant"!`}</p>
    <p>{`Any variable defined with `}<inlineCode parentName="p">{`const`}</inlineCode>{` cannot have its value changed later on (by code)! Lets check out an example where we might break this rule. Let's create two squares that sit on top of each other. We'll be using the `}<inlineCode parentName="p">{`$rect`}</inlineCode>{` command with a few more arguments than we've seen so far - don't worry too much about the details there.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "live=true id=variables-const-error",
        "live": "true",
        "id": "variables-const-error"
      }}>{`// Side length of our first square
const sideLength = 150;

// Create one square
$rect(sideLength, sideLength, 0, 0);

// Side length of our second square
sideLength = 75;

// Create second square
$rect(sideLength, sideLength, 0, 0, { fill: "blue" });
`}</code></pre>
    <p>{`This code `}<em parentName="p">{`almost`}</em>{` works - but we get an error! Since constants must have a constant value, we can't change the value of a constant later on! Things break here because `}<inlineCode parentName="p">{`const sideLength = 150;`}</inlineCode>{` defines `}<inlineCode parentName="p">{`sideLength`}</inlineCode>{` as a `}<em parentName="p">{`constant`}</em>{` variable but `}<inlineCode parentName="p">{`sideLength = 75`}</inlineCode>{` tries to change its value!`}</p>
    <p>{`Let's fix this. In the live code snippet above, change the keyword `}<inlineCode parentName="p">{`const`}</inlineCode>{` to `}<inlineCode parentName="p">{`let`}</inlineCode>{`. What do you notice happened? You should see two squares show up!`}</p>
    <blockquote>
      <p parentName="blockquote">{`The `}<inlineCode parentName="p">{`let`}</inlineCode>{` keyword is a way to create a variable whose value `}<em parentName="p">{`can`}</em>{` change! For example, `}<inlineCode parentName="p">{`let x = 3;`}</inlineCode>{` will create a variable named `}<inlineCode parentName="p">{`x`}</inlineCode>{` whose value is `}<inlineCode parentName="p">{`3`}</inlineCode>{`, but the value of `}<inlineCode parentName="p">{`x`}</inlineCode>{` can be changed later on.`}</p>
    </blockquote>
    <h2 {...{
      "id": "one-last-example"
    }}>{`One last example`}</h2>
    <p>{`Before wrapping up this chapter, let's look at one last example involving variables. We'll be using the `}<inlineCode parentName="p">{`$rect`}</inlineCode>{` command quite a bit throughout this book, so we'll explore some more of the things we can do with it. Go ahead and tinker with the values of the variables defined here and see what happens.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "live=true id=variables-rectangle-params",
        "live": "true",
        "id": "variables-rectangle-params"
      }}>{`// Variables
const width = 100;
const height = 50;
const left = 50;
const top = 100;
const fillColor = "purple";

// Create a rectangle
$rect(width, height, left, top, { fill: fillColor });
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      